<template>
  <b-form class="form" id="frmReporte">
    <b-card no-body class="mb-3">
      <b-card-header class="with-elements">
        <span class="card-header-title mr-2"
          ><i class="fa fa-list-alt"></i> Datos</span
        >
        <div class="card-header-elements"></div>
      </b-card-header>
      <b-card-body>
        <div clasS="form-row">
          <div class="form-group col-md-6">
            <label class="form-label">Sección</label>
            <input
              v-model="reporte.seccion"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label class="form-label">Código</label>
            <input
              v-model="reporte.codigo"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-6">
            <label class="form-label">Título</label>
            <input
              v-model="reporte.titulo"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label class="form-label">Sub Título</label>
            <input
              v-model="reporte.subTitulo"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-12">
            <label class="form-label">Descripción</label>
            <textarea
              v-model="reporte.descripcion"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-12">
            <label class="form-label">Sentencia SELECT</label>
            <prism-editor
              class=" prism-editor"
              v-model="reporte.sentenciaSelect"
              :line-numbers="true"
              :highlight="sqlHighlighter"
              placeholder="Ejm: SELECT TOP 10 FROM Usuario"
            ></prism-editor>
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-12">
            <label class="form-label">Sentencia ORDER BY</label>
            <prism-editor
              class=" prism-editor"
              v-model="reporte.sentenciaOrderBy"
              :line-numbers="true"
              :highlight="sqlHighlighter"
              placeholder="Ejm: ORDER BY 1"
            ></prism-editor>
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-12">
            <label class="form-label">Sentencia JSON FORMAT</label>
            <prism-editor
              class=" prism-editor"
              v-model="reporte.sentenciaJsonFormat"
              :line-numbers="true"
              :highlight="sqlHighlighter"
              placeholder="Ejm: FOR JSON [AUTO|PATH]"
            ></prism-editor>
          </div>
        </div>

        <div clasS="form-row">
          <div class="form-group col-md-12">
            <label class="form-label">Parámetros</label>
            <input
              v-model="reporte.sentenciaParametros"
              type="text"
              class="form-control"
              placeholder="Ejm: nombre#varchar|fecha#datetime"
            />
            <div>
              <small
                >Estructura:
                <span class="font-weight-semibold">nombreParametroA#tipo</span
                ><span class="font-weight-bolder">|</span
                >nombreParametroB#tipo</small
              >
            </div>
            <div>
              <small>
                Tipos de datos:
                <span
                  class="bg-light px-1 ml-1"
                  v-for="tipo in tipoParametro"
                  :key="tipo"
                  >{{ tipo }}</span
                >
              </small>
            </div>
            <br />
            <table v-if="parametros.length" class="table table-sm">
              <thead>
                <tr>
                  <th class=" border-top-0" style="width:300px">Parámetro</th>
                  <th class=" border-top-0" style="width:100px">Tipo</th>
                  <th class=" border-top-0">Validación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(parametro, index) in parametros" :key="index">
                  <td>{{ parametro.nombre }}</td>
                  <td>{{ parametro.tipo }}</td>
                  <td>
                    <span v-if="!parametro.error" class="text-success"
                      ><i class="fa fa-check"></i
                    ></span>
                    <span v-if="parametro.error" class="text-danger">{{
                      parametro.error
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card-body>
      <b-card-footer class="text-right">
        <div class="col-md-12">
          <b-btn class="mr-2" @click="$router.go(-1)"
            ><i class="fa fa-arrow-left"></i> Regresar</b-btn
          >
          <b-btn variant="primary" @click="doSave"
            ><i class="fa fa-save"></i> Guardar</b-btn
          >
        </div>
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<style>
.prism-editor {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}

.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

.prism-editor__container {
  border: 1px solid rgba(24, 28, 33, 0.1);
  border-radius: 0.25rem;
}
</style>

<script>
import Vue from "vue";
import $ from "jquery";
import "parsleyjs";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-tomorrow.css";

import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "edit-data",
  metaInfo: {
    title: "Editar Reporte",
  },
  components: { PrismEditor },
  data: () => ({
    reporte: {
      reporteDataId: null,
      seccion: null,
      codigo: null,
      titulo: null,
      subTitulo: null,
      descripcion: null,
      sentenciaSelect: null,
      sentenciaOrderBy: null,
      sentenciaJsonFormat: null,
      sentenciaParametros: "",

      esEliminado: false,
    },
    tipoParametro: ["int", "varchar", "decimal", "datetime", "bit"],
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {
    parametros: function() {
      var text = this.reporte.sentenciaParametros || "";
      var tokens = text.split("|");

      if (text == "") {
        return [];
      }

      var result = [];

      for (var t = 0; t < tokens.length; t++) {
        var blocks = tokens[t].split("#");

        for (var b = 0; b < blocks.length; b++) {
          blocks[b] = blocks[b].trim();
        }

        if (blocks.length < 2) {
          result.push({
            nombre: tokens[t],
            tipo: "",
            error: "Debe indicar el tipo de parámetro",
          });
        } else if (blocks.length > 2) {
          result.push({
            nombre: blocks[0],
            tipo: blocks[1],
            error: "Cada parámetro debe tener dos secciones nombre#tipo",
          });
        } else if (blocks[0].indexOf(" ") >= 0) {
          result.push({
            nombre: blocks[0],
            tipo: blocks[1],
            error: "El nombre del parámetro no puede contener espacios",
          });
        } else if (this.tipoParametro.indexOf(blocks[1]) == -1) {
          result.push({
            nombre: blocks[0],
            tipo: blocks[1],
            error: "El tipo del parámetro es incorrecto",
          });
        } else {
          result.push({
            nombre: blocks[0],
            tipo: blocks[1],
            error: null,
          });
        }
      }

      return result;
    },
  },
  methods: {
    sqlHighlighter(code) {
      return highlight(code, languages.sql);
    },
    fetchReporte: async function(reporteDataId) {
      debugger;
      var model = {
        reporteDataId: reporteDataId,
      };

      await this.$http
        .get(this.apiRoute("ReportData", "GetReporteById"), { params: model })
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.reporte = response.body.data;
            this.showLoading(false);
          }
        }, this.procesarRespuestaExcepcionDefecto);
    },

    doSave: async function(reemplazarAnterior) {
      var isValid = $("#frmReporte")
        .parsley()
        .validate();

      if (!isValid) {
        await this.sweetAlert(
          "Hay datos pendientes o incorrectos. Revise los campos e intente nuevamente."
        );
        return;
      }

      var model = {
        ...this.reporte,
      };

      this.showLoading();

      var vm = this;

      this.$http
        .post(this.apiRoute("ReportData", "AddEditReporte"), model)
        .then(function(response) {
          this.procesarRespuestaGuardarDefecto(response, null, function() {
            var reporteDataId = response.body.data.reporteDataId;

            vm.$router.replace({
              name: "reports-data-edit",
              params: { reporteDataId: reporteDataId },
            });
            vm.fetchReporte(reporteDataId);
          });
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: async function() {
    this.initParsley();

    var reporteDataId = this.$route.params.reporteDataId;
    if (reporteDataId) {
      this.showLoading();
      await this.fetchReporte(reporteDataId);
    }
    this.activateActivityTracker();
  },
};
</script>
